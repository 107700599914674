import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "px-4 px-md-10 pt-3 pt-md-6"
  }, [_c('div', {
    staticClass: "d-flex pt-4 pb-12"
  }, [_c('div', {
    staticClass: "icon-wrapper rounded-lg align-self-center mr-3"
  }, [_c('span', {
    staticClass: "navigation--menu-icon"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 14L1 8L12 2L23 8L12 14ZM12 18L1.575 12.325L3.675 11.175L12 15.725L20.325 11.175L22.425 12.325L12 18ZM12 22L1.575 16.325L3.675 15.175L12 19.725L20.325 15.175L22.425 16.325L12 22ZM12 11.725L18.825 8L12 4.275L5.175 8L12 11.725Z"
    }
  })])])]), _vm._v(" "), _c('div', {
    staticClass: "hub-title-wrapper align-self-center"
  }, [_c('h5', [_vm._v(_vm._s(_setup.I18nFn("Valuation Model Hub")))]), _vm._v(" "), _c('p', [_vm._v("\n        " + _vm._s(_setup.I18nFn("All of your valuation models in one place, organized by company")) + "\n      ")])]), _vm._v(" "), !_setup.noModelsAtAll || _setup.loading ? _c('div', {
    staticClass: "mr-0 ml-auto text-right align-self-center actions-wrapper"
  }, [_c(VBtn, {
    "class": {
      noBackground: _setup.viewType !== 'grid'
    },
    attrs: {
      "small": "",
      "elevation": "0"
    },
    on: {
      "click": _setup.toggleGrid
    }
  }, [_c(VIcon, {
    attrs: {
      "size": "medium"
    }
  }, [_vm._v("mdi-view-grid-outline")])], 1), _vm._v(" "), _c(VBtn, {
    "class": {
      noBackground: _setup.viewType !== 'table'
    },
    attrs: {
      "small": "",
      "elevation": "0"
    },
    on: {
      "click": _setup.toggleTable
    }
  }, [_c(VIcon, {
    attrs: {
      "size": "medium"
    }
  }, [_vm._v("mdi-format-list-bulleted")])], 1)], 1) : _vm._e()]), _vm._v(" "), _setup.loading ? _c(_setup.ModelHubLoader) : _setup.error ? _c('div', [_vm._v(_vm._s(_setup.error))]) : _setup.noModelsAtAll ? _c('div', {
    staticClass: "text-center empty-models"
  }, [_c('h3', [_vm._v(_vm._s(_setup.I18nFn("Add your first Company to Model")))]), _vm._v(" "), _c('p', [_vm._v("\n      " + _vm._s(_setup.I18nFn("Once your company is created, you can select from a number of valuation frameworks to start analyzing")) + "\n    ")]), _vm._v(" "), _c(VBtn, {
    staticClass: "mt-2 pa-5 rounded-lg btn-add-company white--text",
    "class": {
      'grey--text': _setup.darkActive,
      'text--darken-4': _setup.darkActive
    },
    attrs: {
      "color": _setup.darkActive ? 'primaryAction' : 'grey darken-4',
      "elevation": "0"
    },
    on: {
      "click": function click() {
        return _setup.addCompanyDialog = true;
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-plus-circle-outline")]), _vm._v("\n      " + _vm._s(_setup.I18nFn("Add a company")) + "\n    ")], 1)], 1) : _c('div', [_c('div', {
    staticClass: "mb-4 d-flex flex-column flex-md-row"
  }, [_c('div', {
    staticClass: "search-wrapper"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.searchQuery,
      expression: "searchQuery"
    }],
    staticClass: "text--grey text--lighten-2",
    attrs: {
      "type": "text",
      "placeholder": "Search for your Companies"
    },
    domProps: {
      "value": _setup.searchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _setup.searchQuery = $event.target.value;
      }
    }
  }), _vm._v(" "), _c(VIcon, [_vm._v("mdi-magnify")])], 1), _vm._v(" "), _c('div', {
    staticClass: "w-100 d-flex mt-2 mt-md-0 text-md-left mr-md-0 ml-md-auto align-self-md-center"
  }, [_c(VBtn, {
    staticClass: "mr-0 ml-auto rounded-lg btn-add",
    "class": {
      primaryAction: _setup.darkActive,
      'black--text': _setup.darkActive,
      'text--darken-3': _setup.darkActive
    },
    attrs: {
      "elevation": "0",
      "large": ""
    },
    on: {
      "click": function click() {
        return _setup.addCompanyDialog = true;
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-1",
    attrs: {
      "size": "medium"
    }
  }, [_vm._v("mdi-plus-circle-outline")]), _vm._v("\n          " + _vm._s(_setup.I18nFn("Add a company")) + "\n        ")], 1)], 1)]), _vm._v(" "), _c('SectionToggle', {
    attrs: {
      "initial-state": true,
      "title": "Model Summaries",
      "subtitle": "To add a company to your forecast summary, set your particular model\n        to ‘active’ in the individual company model page.",
      "hide-text": "",
      "show-text": ""
    }
  }, [_setup.hasAnyOutdatedCompany ? _c('div', {
    staticClass: "outdated-msg px-3 py-2 mb-3"
  }, [_c('small', [_vm._v("\n          " + _vm._s(_setup.I18nFn("* Some of your models might be out of date, we recommend that you update them")) + "\n        ")])]) : _vm._e(), _vm._v(" "), _setup.filteredSummaryCompanies.length === 0 ? _c('div', {
    staticClass: "text-center py-6 grey--text text--darken-1"
  }, [_c('h4', [_vm._v(_vm._s(_setup.I18nFn("You do not have any active NTM PE models")))])]) : [_c(_setup.StyledMenu, {
    staticClass: "mb-4",
    attrs: {
      "items": _setup.sortingOptions,
      "initial-value": _setup.amountOfYearsAhead,
      "activator-props": {
        label: 'Filter',
        icon: 'mdi-chevron-down',
        rotateIconOnOpen: true
      },
      "dropdown-min-width": "160px"
    },
    on: {
      "update:value": _setup.handleMenuChange
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "table-wrapper summary-table"
  }, [_c(VDataTable, {
    directives: [{
      name: "fixed-columns",
      rawName: "v-fixed-columns"
    }],
    staticClass: "summaries",
    attrs: {
      "data-fixed-columns-id": "summary",
      "dense": "",
      "items": _setup.filteredSummaryCompanies,
      "headers": _setup.companyHeaders,
      "items-per-page": _setup.pagination.itemsPerPage,
      "item-class": _setup.itemClass,
      "hide-default-header": true,
      "mobile-breakpoint": 0
    },
    on: {
      "click:row": _setup.handleRowClick
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(_ref) {
        var headers = _ref.props.headers;
        return [_c('thead', [_c('tr', {
          staticClass: "groups"
        }, [_c('th'), _vm._v(" "), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v("\n                    " + _vm._s(_setup.I18nFn("Estimated Returns")) + "\n                  ")]), _vm._v(" "), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "3"
          }
        }, [_vm._v("\n                    " + _vm._s(_setup.I18nFn("Fundamental Forecast")) + "\n                  ")]), _vm._v(" "), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "3"
          }
        }, [_vm._v("\n                    " + _vm._s(_setup.I18nFn("Historical Growth")) + "\n                  ")])]), _vm._v(" "), _c('tr', _vm._l(headers, function (header) {
          return _c('th', {
            key: "".concat(header.text).concat(header.value),
            "class": {
              sortable: header.sortable,
              active: _setup.pagination.sortBy && _setup.pagination.sortBy === header.value,
              desc: _setup.pagination.descending,
              asc: !_setup.pagination.descending,
              'text-right': header.align === 'end'
            },
            on: {
              "click": function click($event) {
                return _setup.changeSort(header.value);
              }
            }
          }, [header.sortable && header.align === 'end' ? _c(VIcon, {
            attrs: {
              "small": ""
            }
          }, [_vm._v("\n                      mdi-arrow-up\n                    ")]) : _vm._e(), _vm._v("\n\n                    " + _vm._s(_setup.I18nFn(header.text)) + "\n\n                    "), header.sortable && header.align === 'start' ? _c(VIcon, {
            attrs: {
              "small": ""
            }
          }, [_vm._v("\n                      mdi-arrow-up\n                    ")]) : _vm._e()], 1);
        }), 0)])];
      }
    }, {
      key: "item.lastStockPrice",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v("\n              " + _vm._s(_setup.createCurrencyCell(item === null || item === void 0 ? void 0 : item.latest)) + "\n            ")];
      }
    }, {
      key: "item.targetPrice",
      fn: function fn(_ref3) {
        var _item$targetPrice;
        var item = _ref3.item;
        return [item !== null && item !== void 0 && (_item$targetPrice = item.targetPrice) !== null && _item$targetPrice !== void 0 && _item$targetPrice.unauth ? _c(_setup.UnauthorizedIcon) : [_vm._v("\n                " + _vm._s(_setup.createCurrencyCell(item === null || item === void 0 ? void 0 : item.targetPrice)) + "\n              ")]];
      }
    }, {
      key: "item.realizedDate",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v("\n              " + _vm._s(item.realizedAtDate.text) + "\n            ")];
      }
    }, {
      key: "item.estimatedReturn",
      fn: function fn(_ref5) {
        var _item$estimatedReturn, _item$estimatedReturn2;
        var item = _ref5.item;
        return [item !== null && item !== void 0 && (_item$estimatedReturn = item.estimatedReturn) !== null && _item$estimatedReturn !== void 0 && _item$estimatedReturn.unauth ? _c(_setup.UnauthorizedIcon) : [_vm._v("\n                " + _vm._s(_setup.toPercentage(item === null || item === void 0 || (_item$estimatedReturn2 = item.estimatedReturn) === null || _item$estimatedReturn2 === void 0 ? void 0 : _item$estimatedReturn2.v)) + "\n              ")]];
      }
    }, {
      key: "item.irr",
      fn: function fn(_ref6) {
        var _item$irr, _item$irr2;
        var item = _ref6.item;
        return [item !== null && item !== void 0 && (_item$irr = item.irr) !== null && _item$irr !== void 0 && _item$irr.unauth ? _c(_setup.UnauthorizedIcon) : [_vm._v("\n                " + _vm._s(_setup.toPercentage(item === null || item === void 0 || (_item$irr2 = item.irr) === null || _item$irr2 === void 0 ? void 0 : _item$irr2.v)) + "\n              ")]];
      }
    }, {
      key: "item.forecastRevenueCagr",
      fn: function fn(_ref7) {
        var _item$forecastRevenue;
        var item = _ref7.item;
        return [_vm._v("\n              " + _vm._s(_setup.toPercentage(item === null || item === void 0 || (_item$forecastRevenue = item.forecastRevenueCagr) === null || _item$forecastRevenue === void 0 ? void 0 : _item$forecastRevenue.v)) + "\n            ")];
      }
    }, {
      key: "item.forecastEpsCagr",
      fn: function fn(_ref8) {
        var _item$forecastEpsCagr;
        var item = _ref8.item;
        return [_vm._v("\n              " + _vm._s(_setup.toPercentage(item === null || item === void 0 || (_item$forecastEpsCagr = item.forecastEpsCagr) === null || _item$forecastEpsCagr === void 0 ? void 0 : _item$forecastEpsCagr.v)) + "\n            ")];
      }
    }, {
      key: "item.forecastPeChange",
      fn: function fn(_ref9) {
        var _item$forecastPeChang;
        var item = _ref9.item;
        return [_vm._v("\n              " + _vm._s(_setup.toPercentage(item === null || item === void 0 || (_item$forecastPeChang = item.forecastPeChange) === null || _item$forecastPeChang === void 0 ? void 0 : _item$forecastPeChang.v)) + "\n            ")];
      }
    }, {
      key: "item.historicalRevenueCagr",
      fn: function fn(_ref10) {
        var _item$historicalReven, _item$historicalReven2;
        var item = _ref10.item;
        return [item !== null && item !== void 0 && (_item$historicalReven = item.historicalRevenueCagr) !== null && _item$historicalReven !== void 0 && _item$historicalReven.unauth ? _c(_setup.UnauthorizedIcon) : [_vm._v("\n                " + _vm._s(_setup.toPercentage(item === null || item === void 0 || (_item$historicalReven2 = item.historicalRevenueCagr) === null || _item$historicalReven2 === void 0 ? void 0 : _item$historicalReven2.v)) + "\n              ")]];
      }
    }, {
      key: "item.historicalEpsCagr",
      fn: function fn(_ref11) {
        var _item$historicalEpsCa, _item$historicalEpsCa2;
        var item = _ref11.item;
        return [item !== null && item !== void 0 && (_item$historicalEpsCa = item.historicalEpsCagr) !== null && _item$historicalEpsCa !== void 0 && _item$historicalEpsCa.unauth ? _c(_setup.UnauthorizedIcon) : [_vm._v("\n                " + _vm._s(_setup.toPercentage(item === null || item === void 0 || (_item$historicalEpsCa2 = item.historicalEpsCagr) === null || _item$historicalEpsCa2 === void 0 ? void 0 : _item$historicalEpsCa2.v)) + "\n              ")]];
      }
    }, {
      key: "item.historicalPeChange",
      fn: function fn(_ref12) {
        var _item$historicalPeCha, _item$historicalPeCha2;
        var item = _ref12.item;
        return [item !== null && item !== void 0 && (_item$historicalPeCha = item.historicalPeChange) !== null && _item$historicalPeCha !== void 0 && _item$historicalPeCha.unauth ? _c(_setup.UnauthorizedIcon) : [_vm._v("\n                " + _vm._s(_setup.toPercentage(item === null || item === void 0 || (_item$historicalPeCha2 = item.historicalPeChange) === null || _item$historicalPeCha2 === void 0 ? void 0 : _item$historicalPeCha2.v)) + "\n              ")]];
      }
    }, {
      key: "item.tickersymbol",
      fn: function fn(_ref13) {
        var _setup$getLogo, _setup$getLogo2, _setup$getLogo3;
        var item = _ref13.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [(_setup$getLogo = _setup.getLogo(item.cid)) !== null && _setup$getLogo !== void 0 && _setup$getLogo.data && !((_setup$getLogo2 = _setup.getLogo(item.cid)) !== null && _setup$getLogo2 !== void 0 && _setup$getLogo2.hideLogo) ? _c('img', {
          staticClass: "rounded-circle mr-2 logo",
          attrs: {
            "src": "data:image/jpeg;base64,".concat((_setup$getLogo3 = _setup.getLogo(item.cid)) === null || _setup$getLogo3 === void 0 ? void 0 : _setup$getLogo3.data),
            "alt": "".concat(item.tickersymbol, " Logo"),
            "width": "20",
            "height": "20"
          }
        }) : _c(VSkeletonLoader, {
          staticClass: "skeleton-avatar d-inline-block mr-2",
          attrs: {
            "type": "avatar"
          }
        }), _vm._v(" "), _c('strong', [_vm._v(_vm._s(item.tickersymbol))])], 1)];
      }
    }])
  })], 1)]], 2), _vm._v(" "), _c('div', {
    staticClass: "pt-8"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "mr-0 ml-auto"
  }, [_c('div', {
    staticClass: "d-inline-flex"
  }, [_c('span', {
    staticClass: "sort-label grey--text",
    "class": {
      'text--lighten-1': !_setup.darkActive,
      'text--darken-1': _setup.darkActive
    }
  }, [_vm._v("\n              " + _vm._s(_setup.I18nFn("sort")) + ":\n            ")]), _vm._v(" "), _c(VMenu, {
    attrs: {
      "right": "",
      "offset-y": "",
      "nudge-left": "10px",
      "nudge-bottom": "6px",
      "content-class": "elevation-0 toggle-menu pa-1"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref14) {
        var on = _ref14.on,
          attrs = _ref14.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          staticClass: "ml-1 rounded-lg px-2 dropdown-menu-button",
          attrs: {
            "height": "34",
            "outlined": "",
            "color": _setup.darkActive ? 'white' : 'primary',
            "min-width": "36"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
            }
          }
        }, 'v-btn', attrs, false), on), [_vm._v("\n                  " + _vm._s(_setup.sortBy) + "\n                  "), _c(VIcon, {
          style: {
            transition: 'transform 0.3s',
            transform: _setup.sortByMenu ? 'rotate(180deg)' : 'rotate(0)'
          }
        }, [_vm._v("\n                    mdi-chevron-down\n                  ")])], 1)];
      }
    }]),
    model: {
      value: _setup.sortByMenu,
      callback: function callback($$v) {
        _setup.sortByMenu = $$v;
      },
      expression: "sortByMenu"
    }
  }, [_vm._v(" "), _c(VList, {
    staticClass: "toggle-dropdown rounded-lg elevation-0 py-0"
  }, _vm._l(_setup.sortByObjs, function (item) {
    return _c(VListItem, {
      key: item.name,
      staticClass: "px-2",
      attrs: {
        "dense": ""
      },
      on: {
        "click": function click() {
          return _setup.sortBy = item.name;
        }
      }
    }, [_vm._v("\n                  " + _vm._s(item.name) + "\n                ")]);
  }), 1)], 1)], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "mt-2 mb-4"
  }, [_setup.viewType === 'table' ? [_c('div', {
    staticClass: "table-wrapper filtered-table rounded-lg pa-4"
  }, [_c(VDataTable, {
    directives: [{
      name: "fixed-columns",
      rawName: "v-fixed-columns"
    }],
    attrs: {
      "data-fixed-columns-id": "filtered",
      "dense": "",
      "headers": _setup.tableHeaders,
      "items": _setup.filteredCompanies,
      "loading": _setup.loading,
      "mobile-breakpoint": 0,
      "loading-text": "Loading...",
      "item-class": _setup.itemClass
    },
    on: {
      "click:row": _setup.handleRowClick
    },
    scopedSlots: _vm._u([{
      key: "item.tickersymbol",
      fn: function fn(_ref15) {
        var _item$data$, _setup$getLogo4, _item$data$2;
        var item = _ref15.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_setup.isValidLogo((_item$data$ = item.data[0]) === null || _item$data$ === void 0 ? void 0 : _item$data$.cid) ? _c('img', {
          staticClass: "rounded-circle mr-2 logo",
          attrs: {
            "src": "data:image/jpeg;base64,".concat((_setup$getLogo4 = _setup.getLogo((_item$data$2 = item.data[0]) === null || _item$data$2 === void 0 ? void 0 : _item$data$2.cid)) === null || _setup$getLogo4 === void 0 ? void 0 : _setup$getLogo4.data),
            "alt": "".concat(item.tickersymbol, " Logo"),
            "width": "20",
            "height": "20"
          }
        }) : _c(VSkeletonLoader, {
          staticClass: "skeleton-avatar d-inline-block mr-2",
          attrs: {
            "type": "avatar"
          }
        }), _vm._v(" "), _c('strong', [_vm._v(_vm._s(item.tickersymbol))]), _vm._v(" "), !item.data.find(function (i) {
          return i.isActive;
        }) ? _c(VTooltip, {
          attrs: {
            "bottom": "",
            "max-width": "300px",
            "z-index": 1000
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref16) {
              var on = _ref16.on;
              return [_c(VIcon, _vm._g({
                staticClass: "ml-1",
                attrs: {
                  "small": "",
                  "color": "red"
                }
              }, on), [_vm._v("\n                        mdi-information-outline\n                      ")])];
            }
          }], null, true)
        }, [_vm._v(" "), _c('span', [_vm._v("\n                      " + _vm._s(_setup.I18nFn("You do not have any active models for this company. We recommend you add one to your hub summary for an easy way to track your projections")) + "\n                    ")])]) : _vm._e()], 1)];
      }
    }, {
      key: "item.data",
      fn: function fn(_ref17) {
        var _item$data;
        var item = _ref17.item;
        return [_vm._v("\n                " + _vm._s(item === null || item === void 0 || (_item$data = item.data) === null || _item$data === void 0 ? void 0 : _item$data.length) + "\n              ")];
      }
    }, {
      key: "item.lastModified",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_vm._v("\n                " + _vm._s(_setup.dayjs(item.lastModified).format("MMM D YYYY HH:mm")) + "\n              ")];
      }
    }, {
      key: "item.createdAt",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_vm._v("\n                " + _vm._s(_setup.dayjs(item.createdAt).format("MMM D YYYY HH:mm")) + "\n              ")];
      }
    }, {
      key: "body.append",
      fn: function fn() {
        return [_c('tr', {
          staticClass: "table-add-row",
          on: {
            "click": function click() {
              return _setup.addCompanyDialog = true;
            }
          }
        }, [_c('td', {
          attrs: {
            "colspan": _setup.tableHeaders.length
          }
        }, [_c(VBtn, {
          staticClass: "table-add",
          attrs: {
            "elevation": "0",
            "ripple": false
          },
          on: {
            "click": function click() {
              return _setup.addCompanyDialog = true;
            }
          }
        }, [_c(VIcon, {
          staticClass: "mr-1"
        }, [_vm._v("mdi-plus-circle-outline")]), _vm._v("\n                      " + _vm._s(_setup.I18nFn("Add a valuation model")) + "\n                    ")], 1)], 1)])];
      },
      proxy: true
    }, {
      key: "item.actions",
      fn: function fn(_ref20) {
        var _setup$deletingModels;
        var item = _ref20.item;
        return [_c('ConfirmationDialog', {
          attrs: {
            "title": "Delete Company",
            "confirm-label": "Delete",
            "disabled-btn": false,
            "manual-hide": true,
            "show-confirm-loader": item.data[0].metadata.companyid === ((_setup$deletingModels = _setup.deletingModelsId) === null || _setup$deletingModels === void 0 ? void 0 : _setup$deletingModels.cid)
          },
          on: {
            "confirm": function confirm(ref) {
              return _setup.handleDeleteCard(ref, item);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref21) {
              var on = _ref21.on;
              return [_c(VIcon, _vm._g({
                staticClass: "delete-icon",
                attrs: {
                  "small": ""
                },
                on: {
                  "click": function click($event) {
                    $event.preventDefault();
                  }
                }
              }, on), [_vm._v("\n                      mdi-delete-outline\n                    ")])];
            }
          }], null, true)
        }, [_vm._v(" "), _c('p', [_vm._v("\n                    " + _vm._s(_setup.I18nFn("Are you sure you want to delete all the models for")) + "\n                    "), _c('strong', [_vm._v(_vm._s(item.tickersymbol))]), _vm._v("\n                    ?\n                  ")])])];
      }
    }], null, false, 1395746505)
  })], 1)] : [_c('transition-group', {
    staticClass: "grid-view",
    attrs: {
      "name": "flip-list",
      "tag": "ul"
    }
  }, _vm._l(_setup.filteredCompanies, function (company) {
    var _company$data$, _company$data$2, _setup$deletingModels2, _company$data$3;
    return _c('li', {
      key: "".concat(company.tickersymbol).concat(company === null || company === void 0 ? void 0 : company.companyname),
      on: {
        "click": function click($event) {
          _setup.$router.push(_setup.createEditLink(company.data));
        }
      }
    }, [_c(_setup.ModelHubCard, {
      attrs: {
        "company-name": company.companyname,
        "cid": (_company$data$ = company.data[0]) === null || _company$data$ === void 0 ? void 0 : _company$data$.metadata.companyid,
        "tid": (_company$data$2 = company.data[0]) === null || _company$data$2 === void 0 ? void 0 : _company$data$2.metadata.tradingitemid,
        "tickersymbol": company === null || company === void 0 ? void 0 : company.tickersymbol,
        "has-active-items": company.data.filter(function (i) {
          return i.isActive;
        }).length > 0,
        "amount-of-models": company.data.length,
        "last-modified": company === null || company === void 0 ? void 0 : company.lastModified,
        "created-at": company === null || company === void 0 ? void 0 : company.createdAt,
        "loading": ((_setup$deletingModels2 = _setup.deletingModelsId) === null || _setup$deletingModels2 === void 0 ? void 0 : _setup$deletingModels2.cid) === ((_company$data$3 = company.data[0]) === null || _company$data$3 === void 0 ? void 0 : _company$data$3.metadata.companyid)
      },
      on: {
        "delete": function _delete(ref) {
          return _setup.handleDeleteCard(ref, company);
        }
      }
    })], 1);
  }), 0)]], 2)])], 1), _vm._v(" "), _c(_setup.StyledDialog, {
    attrs: {
      "visible": _setup.addCompanyDialog,
      "title": "Add a Company",
      "scrollable": "",
      "width": _setup.$vuetify.breakpoint.mdAndUp ? '800px' : '',
      "max-width": _setup.$vuetify.breakpoint.mdAndUp ? '800px' : '600px',
      "content-props": {
        height: _setup.$vuetify.breakpoint.mdAndUp ? '700px' : ''
      },
      "actions-props": {
        "class": 'pa-0 pt-0 search-dialog-actions'
      },
      "close-btn-within-title-section": true
    },
    on: {
      "update:visible": _setup.handleUpdateVisible
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c(VSpacer), _vm._v(" "), _c(VBtn, {
          staticClass: "btn-add-modal",
          attrs: {
            "text": ""
          },
          on: {
            "click": _setup.handleAddCompany
          }
        }, [_vm._v("\n        " + _vm._s(_setup.I18nFn("Add")) + "\n      ")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "search"
  }, [_c(VIcon, [_vm._v("mdi-magnify")]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.modalSearchQuery,
      expression: "modalSearchQuery"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _setup.modalSearchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _setup.modalSearchQuery = $event.target.value;
      }
    }
  }), _vm._v(" "), _setup.loadingSearch ? _c(VProgressCircular, {
    staticClass: "company-search",
    attrs: {
      "size": "20",
      "width": "2",
      "color": _setup.darkActive ? 'primaryAction' : 'toolbarText',
      "indeterminate": ""
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('h6', {
    staticClass: "companies-title"
  }, [_vm._v(_vm._s(_setup.I18nFn("Companies")))]), _vm._v(" "), _c('ul', {
    staticClass: "company-list"
  }, [_vm._l(_setup.entities, function (company) {
    var _setup$selectedCompan, _setup$selectedCompan2, _setup$selectedCompan3, _setup$getLogo5;
    return _c('li', {
      key: company.id,
      staticClass: "d-flex",
      "class": {
        selected: ((_setup$selectedCompan = _setup.selectedCompany) === null || _setup$selectedCompan === void 0 ? void 0 : _setup$selectedCompan.name) === company.tickersymbol && ((_setup$selectedCompan2 = _setup.selectedCompany) === null || _setup$selectedCompan2 === void 0 ? void 0 : _setup$selectedCompan2.cid) === company.companyid && ((_setup$selectedCompan3 = _setup.selectedCompany) === null || _setup$selectedCompan3 === void 0 ? void 0 : _setup$selectedCompan3.tid) === company.tradingitemid
      },
      on: {
        "click": function click() {
          return _setup.setEntity(company);
        }
      }
    }, [_setup.isValidLogo(company.companyid) ? _c('img', {
      staticClass: "rounded-circle mr-2",
      attrs: {
        "src": "data:image/jpeg;base64,".concat((_setup$getLogo5 = _setup.getLogo(company.companyid)) === null || _setup$getLogo5 === void 0 ? void 0 : _setup$getLogo5.data),
        "alt": "".concat(company.tickersymbol, " Logo"),
        "width": "20",
        "height": "20"
      }
    }) : _c('div', {
      staticClass: "rounded-circle mr-2 flex-grow-0 logo-placeholder"
    }), _vm._v(" "), _c('strong', {
      staticClass: "flex-grow-0"
    }, [_vm._v(_vm._s(company.tickersymbol))]), _vm._v(" "), _c('span', {
      staticClass: "text-truncate flex-grow-1",
      attrs: {
        "title": company.companyname
      }
    }, [_vm._v("\n          " + _vm._s(company.companyname) + "\n        ")]), _vm._v(" "), _c('span', {
      staticClass: "ml-auto mr-0 flex-grow-1 grow-1 text-right"
    }, [_vm._v("\n          " + _vm._s(company.exchangesymbol) + "\n        ")]), _vm._v(" "), _c('span', {
      staticClass: "ml-2 mr-0"
    }, [_vm._v("\n          " + _vm._s(_setup.getFlagByCountryId(company.primaryexchangecountryid)) + "\n        ")])]);
  }), _vm._v(" "), _setup.entities.length === 0 ? _vm._l(_setup.filteredCompaniesModal, function (company) {
    var _setup$selectedCompan4, _setup$getLogo6, _setup$mappedEmojis;
    return _c('li', {
      key: company.name,
      staticClass: "d-flex",
      "class": {
        selected: ((_setup$selectedCompan4 = _setup.selectedCompany) === null || _setup$selectedCompan4 === void 0 ? void 0 : _setup$selectedCompan4.name) === company.name
      },
      on: {
        "click": function click() {
          return _setup.setCompany(company);
        }
      }
    }, [_setup.isValidLogo(company.cid) ? _c('img', {
      staticClass: "rounded-circle mr-2",
      attrs: {
        "src": "data:image/jpeg;base64,".concat((_setup$getLogo6 = _setup.getLogo(company.cid)) === null || _setup$getLogo6 === void 0 ? void 0 : _setup$getLogo6.data),
        "alt": "".concat(company.name, " Logo"),
        "width": "20",
        "height": "20"
      }
    }) : _vm._e(), _vm._v(" "), _c('strong', [_vm._v(_vm._s(company.name))]), _vm._v(" "), _c('span', [_vm._v(_vm._s(company.desc))]), _vm._v(" "), _c('span', {
      staticClass: "ml-auto mr-0 flex-grow-1 grow-1 text-right"
    }, [_vm._v("\n            " + _vm._s(company.exchangesymbol) + "\n          ")]), _vm._v(" "), (_setup$mappedEmojis = _setup.mappedEmojis) !== null && _setup$mappedEmojis !== void 0 && _setup$mappedEmojis[company.country] ? _c('span', {
      staticClass: "ml-2 mr-0"
    }, [_vm._v("\n            " + _vm._s(_setup.mappedEmojis[company.country]) + "\n          ")]) : _vm._e()]);
  }) : _vm._e()], 2)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };